class WebauthnUtil {
	constructor() {
		this.challenge = new Uint8Array(32);
		window.crypto.getRandomValues(this.challenge);
	}

	createPublicKey(user) {
		let id = '' + user.id;
		id = Uint8Array.from(id, c=>c.charCodeAt(0));
		let publicKey = {
			timeout: 30000,
			challenge: this.challenge,
			rp: {
				name: process.env.VUE_APP_RP_ID,
			},
			user: {
				id: id,
				name: user.email,
				displayName: user.vorname + ' ' + user.nachname,
			},
			pubKeyCredParams: [
				{ type: 'public-key', alg: -7  },
				{ type: 'public-key', alg: -257 },
			],
		}
		return navigator.credentials.create({publicKey});
	}

	getPublicKey(id) {
		id = this.base64ToArrayBuffer(id);
		let publicKey = {
			publicKey: {
				timeout: 30000,
				challenge: this.challenge,
				userVerification: 'discouraged',
				allowCredentials: [
					{
						id: id,
						transports: ['usb', 'nfc', 'ble', 'internal'],
						type: 'public-key',
					}
				]
			}
		};
		return navigator.credentials.get(publicKey);
	}

	arrayBufferToBase64(buffer) {
		let binary = '';
		let bytes = new Uint8Array(buffer);
		let len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode( bytes[ i ] );
		}
		return window.btoa(binary);
	}

	base64ToArrayBuffer(str) {
		let binary_string = window.atob(str);
		let len = binary_string.length;
		let bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}
}

export default new WebauthnUtil();
